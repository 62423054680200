
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Documents } from '@/store/depot/types';
import DocumentsList from '@/components/depot/DocumentsList.vue';
import { PatDocument } from '@/store/pat/types';
import InterestCertificateList from '@/components/pat/InterestCertificateList.vue';

@Component({
  name: 'documents-view',
  components: {
    InterestCertificateList,
    DocumentsList,
  },
})
export default class DocumentsView extends Vue {
  @Getter('depot/documents') documents?: Documents;
  @Getter('pat/documents') interestCertificates?: Array<PatDocument>;
  @Getter('depot/loadingDepot') loadingDocuments?: Boolean;
  @Getter('pat/loadingData') loadingPatData?: Boolean;

  getSecurityDocuments() {
    if (this.documents.securityEvents.data && this.documents.securityEventsOld.data) {
      return this.documents.securityEvents.data.concat(this.documents.securityEventsOld.data);
    } else if (this.documents.securityEvents.data) {
      return this.documents.securityEvents.data;
    } else {
      return this.documents.securityEventsOld.data;
    }
  }
}
