
import { Component, Vue, Prop } from 'vue-property-decorator';
import { currency } from '@/filters/currency';
import { dateString } from '@/filters/date';

@Component({
  name: 'documents-list',
  filters: {
    currency,
    dateString,
  },
})
export default class DocumentsOverviewCard extends Vue {
  @Prop({ default: undefined, required: true }) title?: string;
  @Prop({ default: undefined, required: true }) data?: Array<File>;
  @Prop({ default: undefined, required: true }) isLoading?: Boolean;


  get hasData() {
    return this.data && this.data.length > 0;
  }
}
