
import { Component, Vue, Prop } from 'vue-property-decorator';
import { currency } from '@/filters/currency';
import { dateString } from '@/filters/date';
import { PatDocument } from '@/store/pat/types';
import { Action } from 'vuex-class';

@Component({
  name: 'interest-certificate-list',
  filters: {
    currency,
    dateString,
  },
})
export default class InterestCertificateList extends Vue {
  @Prop({ default: undefined, required: true }) title?: string;
  @Prop({ default: undefined, required: true }) interestCertificates?: Array<PatDocument>;
  @Prop({ default: undefined, required: true }) isLoading?: Boolean;

  @Action('pat/downloadDocument') downloadCall: Function ;

  get hasData() {
    return this.interestCertificates && this.interestCertificates.length > 0;
  }
}
